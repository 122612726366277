if (sessionStorage.getItem('loginTime') && sessionStorage.getItem('loginTime') !== '') {
  const loginTime = Number(sessionStorage.getItem('loginTime'));
  const currentTime = new Date().getTime();
  const diff = (currentTime - loginTime) / 1000;
  if (diff > 10) {
    sessionStorage.clear();
    location.replace('/login');
  } else {
    import('./loadVue.js');
  }
} else {
  sessionStorage.clear();
  location.replace('/login');
}

setInterval(() => {
  sessionStorage.setItem('loginTime', new Date().getTime());
}, 5000);
